var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selected)?_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
    _vm.$v.temp_profile.end_semester_temp.$dirty
      ? _vm.temp_profile.end_semester_temp > _vm.semester_amount
        ? 'No puede ser mayor a la cantidad de nivel especificada en el Perfil.'
        : _vm.temp_profile.end_semester_temp < 0
        ? 'El semetre final no puede ser menor a 0'
        : ''
      : ''
  ),expression:"\n    $v.temp_profile.end_semester_temp.$dirty\n      ? temp_profile.end_semester_temp > semester_amount\n        ? 'No puede ser mayor a la cantidad de nivel especificada en el Perfil.'\n        : temp_profile.end_semester_temp < 0\n        ? 'El semetre final no puede ser menor a 0'\n        : ''\n      : ''\n  ",modifiers:{"v-secondary":true}}],staticClass:"m-0",attrs:{"size":"sm","type":"number","state":_vm.validateState('end_semester_temp')},on:{"change":_vm.updateEndSemester},model:{value:(_vm.$v.temp_profile.end_semester_temp.$model),callback:function ($$v) {_vm.$set(_vm.$v.temp_profile.end_semester_temp, "$model", $$v)},expression:"$v.temp_profile.end_semester_temp.$model"}}):_c('b-form-input',{staticClass:"m-0",attrs:{"size":"sm","type":"number","disabled":!_vm.selected}})
}
var staticRenderFns = []

export { render, staticRenderFns }