<template>
  <b-form-input
    v-if="selected"
    size="sm"
    class="m-0"
    type="number"
    v-b-tooltip.v-secondary="
      $v.temp_profile.end_semester_temp.$dirty
        ? temp_profile.end_semester_temp > semester_amount
          ? 'No puede ser mayor a la cantidad de nivel especificada en el Perfil.'
          : temp_profile.end_semester_temp < 0
          ? 'El semetre final no puede ser menor a 0'
          : ''
        : ''
    "
    v-model="$v.temp_profile.end_semester_temp.$model"
    :state="validateState('end_semester_temp')"
    @change="updateEndSemester"
  >
  </b-form-input>
  <b-form-input
    v-else
    size="sm"
    class="m-0"
    type="number"
    :disabled="!selected"
  >
  </b-form-input>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, maxValue, minValue } from "vuelidate/lib/validators";
export default {
  name: "EgressProfileCycleEndSemester",
  mixins: [validationMixin],
  props: {
    end_semester: {
      required,
    },
    semester_amount: {
      required,
    },
    selected: {
      required,
    },
    profile_cycle_id: {
      required,
    },
  },
  data() {
    return {
      temp_profile: {
        end_semester_temp: this.end_semester,
      },
    };
  },
  validations() {
    return {
      temp_profile: {
        end_semester_temp: {
          required,
          maxValue: maxValue(this.semester_amount),
          minValue: minValue(0),
        },
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.temp_profile[name];
      return $dirty ? !$error : null;
    },
    updateEndSemester() {
      this.$v.temp_profile.$touch();
      if (this.$v.temp_profile.$anyError) return;
      this.$emit(
        "updated",
        this.temp_profile.end_semester_temp,
        this.profile_cycle_id
      );
    },
  },
};
</script>

<style>
</style>